
footer {
	padding: 2rem 1.25rem .875rem 1.25rem;
	background-color: $dark;
	color: $white;
	width: 100%;
	@include media-breakpoint-up(xl) {
		position: -webkit-sticky;
		position: sticky;
		bottom: 0;
		left: 0;
		z-index: -2;
	}
	a {
		color: #fff;
		cursor: pointer;
		&:hover {
			color: $primary;
			transition:color .3s ease-out;
		}
	}
	.list-unstyled {
		.small {
			text-decoration: none;
			font-weight: 100;
			&:hover {
				text-decoration: underline;
			}
		}
	}

.locations-container {
	display: grid;
	grid-gap: 2rem;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

	.location {
		padding-bottom: 1rem;
		h6 {
			font-size: .75rem;
			color: $medium;
			text-transform: uppercase;
		}
		p {
			font-size: .75rem;
			font-weight: 100;
			color: $light;
		}
	}

	.footer-legal {
		text-align: left;
		img {
			width: 10rem;
			margin-bottom: 2rem;
		}
		@include media-breakpoint-down(md) {
			text-align: center;
		}
	}

	.footer-contact {
		text-align: right;
		@include media-breakpoint-down(md) {
			text-align: center;
		}

	}


	.border-top {
		border-top: .02rem solid rgba($medium,.3) !important;
		@include media-breakpoint-down(md) {
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-direction: column;
		}
	}

	.bottom-footer {
		display: flex;
		flex-direction: row;
		justify-content: space-around;
		align-items: center;
		padding-top: 1.5rem;
		border-top: .02rem solid rgba($medium,.3) !important;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}
	}

	.social-links {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: flex-end;
		li{
			font-weight: bold;
			font-size: .875em;
			a {
				padding: 1em;
			}
		}
		@include media-breakpoint-down(md) {
			text-align: center;
			justify-content: center;
		}
	}
	@include media-breakpoint-down(md) {
		text-align: center;
	}

}
.lan-icon {
	height: 1rem;
	margin-top: -.25rem;
	margin-right: .5rem;
}
