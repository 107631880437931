@import '../variables/_colors.scss';

html,body {
	// background-color: rgba($white,0);
	color: $dark;
	font-family: 'Jost*', sans-serif;
	line-height: 1.4;
	transition: .3s;
	z-index: -10;
	position: relative;
}

#root{
	color: #262626;
    font-family: "Jost*", sans-serif;
    line-height: 1.4;
    transition: 0.3s;
    z-index: -10;
    position: relative;
}

main {
	z-index: 10;
}
h1,h2,h3,h4,h5,h6 {
	font-family: 'DM Sans', sans-serif;
	font-weight: 900;
	line-height: 1.4;

}

h1 {
	font-size: 3rem;
}

h2 {
	font-size: 2.375rem;
}

h3 {
	font-size: 1.875rem;
}

h4 {
	font-size: 1.5rem;
}

h5 {
	font-size: 1.25rem;
}

h6 {
	font-size: 1.125rem;
		padding-top: 1rem;
}

p {
	font-size: 1rem;
	line-height: 1.3;
	margin-bottom: .5rem;
}

.small {
	font-size: 85%;
}
// Font type
.f-sans {
	font-family: 'DM Sans', sans-serif;
}
.f-serif {
	font-family: 'NewYork', serif;
	font-weight: 100;
}
// Color
.c-primary {
	color: $primary;
}
.c-secondary {
	color: $secondary;
}
.c-tertiary {
	color: $tertiary;
}
// Background Colors
.bg-white {
	background-color: $white !important;
}
.bg-light {
	background-color: $light !important;
}
.bg-medium {
	background-color: $medium !important;
}
.bg-dark {
	background-color: $dark !important;
}
.bg-primary {
	background-color: $primary !important;
}
.bg-secondary {
	background-color: $secondary !important;
}
.bg-tertiary {
	background-color: $tertiary !important;
}
// Visibility
.only-desktop {
	@include media-breakpoint-down(lg) {
		display: none;
	}
}
.only-mobile {
	@include media-breakpoint-up(lg) {
		display: none;
	}
}


// login cover container
.login-page {
	min-width: 100vw;
	height: 100vh;
	min-height: 46rem;
	// background-image: linear-gradient(rgba($dark, 0.6), rgba($dark, 0.6)), url("../img/bg-01.jpg");
	// background-repeat: no-repeat;
	// background-position: center center;
	// background-size: cover;

	.login-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100vh;
		min-height: 46rem;
		word-break: break-word;
		@include media-breakpoint-down(lg) {
			flex-direction: column;
		}

		.backgroundClass{
			background: linear-gradient(rgba(0, 0, 0, .5), rgba(0, 0, 0, .5)), url(../../assets/img/bg-05.jpg);
		}

		.login-left{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100vh;
			min-height: 46rem;
			max-width: 72rem;
			margin-left: auto;
			margin-right: auto;
			word-break: break-word;
			width: 50%;
			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}

		.login-right{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			height: 100vh;
			min-height: 46rem;
			max-width: 72rem;
			margin-left: auto;
			margin-right: auto;
			word-break: break-word;
			width: 50%;
			@include media-breakpoint-down(lg) {
				width: 100%;
			}
		}

		.login-page-text {
			width: 70%;
			@include media-breakpoint-down(lg) {
				width: 90%;
			}

		}
		.login-welcome {
			font-family: 'DM Sans', sans-serif;
			font-size: 1.75rem;
			color: $primary;
			margin-bottom: 1.25rem;
			font-weight: 300;
			@include media-breakpoint-down(sm) {
				font-size: 1.25rem;
			}
		}
		.login-title {
			font-size: 5rem;
			color: $white;
			text-transform: uppercase;
			line-height: 1;
			@include media-breakpoint-down(md) {
				font-size: 2.75rem;
			}
		}
		.login-description {
			font-size: 1.25rem;
			color: $light;
			margin-top: 2rem;
			line-height: 1.4;
			font-weight: 500;
			font-family: 'Jost*', sans-serif;
			@include media-breakpoint-down(lg) {
				margin-bottom: .25rem;
			}
			@include media-breakpoint-down(sm) {
				font-size: 1rem;
				margin-top: .25rem;
			}
		}

		.login-page-form{
			background-color: rgba($white,1);
			width: 60%;
			@include media-breakpoint-down(lg) {
				width: 90%;
			}

			.login-page-logo {
				display: flex;
				justify-content: center;
				padding-bottom: 4em;
			}
			.login-form-title {
				font-family: 'DM Sans', sans-serif;
				font-size: 1.5rem;
				color: $primary;
				font-weight: 100;
				margin-bottom: 0;
			}
			.login-form-subtitle {
				font-family: 'DM Sans', sans-serif;
				font-size: .875rem;
				color: rgba($medium,.6);
				font-weight: 100;
				margin-bottom: 2rem;
			}
		}
	}
}

.header-actions {
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	align-items: center;
	padding-bottom: 2rem;
	flex-wrap: wrap;
	.actions {
		display: flex;
		flex-direction: row;
		align-items: center;

		form{
			width: auto;
		}
		p {
			display: flex;
			margin: 0;
			margin-left: 1rem;
			margin-right: 1rem;
			align-items: center;
			cursor: pointer;

			svg{
				margin-right: 0.5rem;
			}
		}
		.btn {
			display: inline-block;
			min-width: 9rem;
			padding: 0.5rem;
			margin-left: 1rem
		}
	}
}

.checkbox-permission-edit-user-form-wrapper{
	display: flex;
	flex-direction: column;

	.checkbox-permission-edit-user-form-item{
		label{
			margin-left: 0.2rem;
		}
	}


}


// CSS impide seleccionar los elementos de la web !
body{
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
}

.location p {
	margin-bottom: 0rem;
}

.errorMessage {
	display: block;
	// text-align: center;
	margin-top: 0.5rem;
	color: $error-fg;
}
.errorMessageCenter {
	display: block;
	text-align: center;
	margin-top: 1.5rem;
	color: $error-fg;
}

.centeredButton {
	display: block;
	text-align: center;
	margin-right: auto !important;
    margin-left: auto !important;
	margin-top: 2rem !important;
}

.inline-text {
	display: flex;
    justify-content: space-between;
}

.inline-text-between {
    display: flex;
    justify-content: flex-start;
    gap: 18px;
}

.w-35 {
	width: 35%;
}

.table-header {
	border-bottom: 2px solid transparent;
}

.table-header-title {
	color: gray;
	&:hover, &:active, &:focus{
		color: darken($primary, 5%);
		background-color: transparent;
	}
}
.activeRow {
	background-color: rgba(196, 157, 104, 0.2) !important;
}
.cell-table{
	padding: 1rem ;
}
.scroll-tab {

}

.no-button-appearance button, .no-button-appearance button:hover, .no-button-appearance button:active, .no-button-appearance button:focus  {
    background-color: transparent;
	border: 0;
	box-shadow: unset;
	padding: 0;
}

.iconDots {
	height: 1.25rem;
    width: 1.25rem;
    margin-top: -0.125rem;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
}

.dropstart .dropdown-toggle::before, .dropdown-toggle::after {
    display: none !important;
}

.no-button-appearance #dropdown-button-drop-end {
	padding: 1rem 0rem;
}

.dropdown-item {
	display: flex;
    gap: 6px;
}
.dropdown-item > .dropdown-string-action {
	margin-top: auto;
    margin-bottom: auto;
}

.nav-pills .nav-item {
    cursor: pointer;
}

.form-floating > .email-create-user:focus, .form-floating > .email-create-user:not(:placeholder-shown) {
    padding-top: 3.625rem;
    padding-bottom: 0.825rem;
}

// Field required
.is-required:after{
	content: '*';
	color: $red;
	font-weight: $co-font-bold;
}

.settings-menu-container{
	a{
		div{
			display: flex;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			width: 100%;

			li{
				padding-left: 0.5rem !important;
			}

		}

		span{
			display: flex;
			justify-content: center;
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			width: 100%;
		}
	}

	div{
		width: 100%;
	}
}

.header-section-spinner{
	display: flex;
	justify-content: center;
}
