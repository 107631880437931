.pagination-wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  flex-wrap: wrap;

  .elements-container{
    span{
      font-weight: bold;
    }
  }

  .pagination-container{
    display: flex;
    flex-direction: row;

    .pagination-button{
      padding: 0;
      margin-right: 0.25rem;
      margin-left: 0.25rem;
    }

    .pagination-dots{
      margin-right: auto;
      margin-left: auto;
    }
  }
}
