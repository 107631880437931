@import 'src/styles/variables/all';

// Fichero de ejemplo para modificar un componente

::-webkit-scrollbar {
    width: 8px;
}

::-webkit-scrollbar-track {
    background: transparent;
}

::-webkit-scrollbar-thumb {
    background: $co-color-gray02;
    border-radius: 4px;
}
