@font-face {
    font-family: 'Jost*';
    src: url('Jost-Medium.woff2') format('woff2'),
        url('Jost-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Jost*';
    src: url('Jost-Semi.woff2') format('woff2'),
        url('Jost-Semi.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'NewYork';
    src: url('NewYork.woff2') format('woff2'),
        url('NewYork.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Jost*';
    src: url('Jost-Light.woff2') format('woff2'),
        url('Jost-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'DM Sans';
    src: url('DMSans-Bold.woff2') format('woff2'),
        url('DMSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Jost*';
    src: url('Jost-Bold.woff2') format('woff2'),
        url('Jost-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Jost*';
    src: url('Jost-Book.woff2') format('woff2'),
        url('Jost-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

