// SWIPER SLIDER

.swiper {
	width: 100%;
	height: auto;


	.swiper-wrapper {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		flex-direction: row;

		.swiper-slide {
			display: flex;
			justify-content: flex-start;
			flex-direction: column;
			align-items: center;
			text-align: center;
			img {
				width: 100%;
				height: 100%;
				@include media-breakpoint-up(sm) {
					width: 12rem;
					margin-bottom: 1rem;
				}
				@include media-breakpoint-up(md) {
					width: 15rem;
					margin-bottom: 1rem;
				}
			}
			.swiper-slide-title {
				font-weight: 900;
			}
			/*.swiper-slide-description {

			}*/
		}

		.swiper-congrat {
			box-shadow: 0 0 .625rem rbga($black,.22);
			border-radius: .625rem;
			padding: 2rem 1rem 1rem 1rem;
			.swiper-slide-img {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 30rem;
				height: 3.25rem;
				width: 3.25rem;
				background-color: rgba($white,.27);
				margin-bottom: 1rem;
				img {
					width: 2rem;
					height: 2rem;
					margin: 0;
				}
			}
			.swiper-slide-name {
				color: $white;
				font-size: 1rem;
				line-height: 1.15;
				margin-bottom: 1rem;
				sub {
					color: $white;
				}
			}
			.swiper-slide-quantity {
				color: $white;
				font-weight: 900;
				font-size: 1.5rem;
				margin-bottom: -.5rem;
			}
			.swiper-slide-unit {
				color: $white;
				font-weight: 900;
				font-size: 1rem;
			}
		}
		.bg-1 {
			background-color: #10C177;
		}
		.bg-2 {
			background-color: #FDBF00;
		}
		.bg-3 {
			background-color: #FF6C6C;
		}
		.bg-4 {
			background-color: #76E2F8;
		}
		.bg-5 {
			background-color: #44D7B6;
		}
		.bg-6 {
			background-color: #FFD400;
		}
		.bg-7 {
			background-color: #2AD414;
		}
		.bg-8 {
			background-color: #7E5DF0;
		}
	}
	.swiper-pagination {
		position: relative;
		margin-top: 1rem;
	}
	.swiper-pagination-bullet {
		background: $primary;
	}

	@include media-breakpoint-up(sm) {
		margin-bottom: 2rem;
	}

}



.swiper {
	width: 100%;
	height: 100%;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: flex;
	justify-content: center;
	align-items: center;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}

.swiper {
	width: 100%;
	height: 300px;
	margin-left: auto;
	margin-right: auto;
}

.swiper-slide {
	background-size: cover;
	background-position: center;
	cursor: pointer;
}

.swiper-product-detail-big {
	height: 80%;
	width: 100%;
}

.swiper-product-detail {
	height: 20%;
	box-sizing: border-box;
	padding: 10px 0;
	margin-top: 1rem;
}

.swiper-product-detail .swiper-slide {
	width: 25%;
	height: 100%;
	opacity: 0.2;
	cursor: pointer;
	transition: .1s;
	&:hover {
		opacity: 1;
	}
}

.swiper-product-detail .swiper-slide-thumb-active {
	opacity: 1;
}

.swiper-slide img {
	display: block;
	width: 100%;
	height: 100%;
	-o-object-fit: cover;
	object-fit: cover;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
	background-color: $primary;
}
.swiper-pagination.swiper-pagination-bullets.swiper-pagination-horizontal {
	bottom: 0;
}

.swiper-pagination {
    position: relative !important;
    margin-top: auto !important;
    bottom: 0 !important;
    margin-bottom: -2rem !important;
}
