.navbar {
    max-height: 5.375rem;
    background-color: $white;
    // box-shadow: 1rem 1rem 1rem rgba($dark,.05);
    border-bottom: 0.031rem solid rgba($dark, 0.05);
    @include media-breakpoint-down(lg) {
        padding: 0.5rem;
    }
    .navbar-toggler {
        position: absolute;
        left: 0.875rem;
        top: 1.5rem;
        @include media-breakpoint-down(sm) {
            top: 1rem;
        }
    }
}

.navbar-brand {
    opacity: 1;
    margin-left: auto;
    margin-right: auto;
    img {
        max-height: 2.5rem;
    }
    @include media-breakpoint-down(sm) {
        img {
            max-height: 1.75rem;
        }
    }
}

.navbar-nav {
    // justify-content: flex-end;
    align-items: center;
    width: 100%;
    text-align: center;
    @include media-breakpoint-down(lg) {
        padding-top: 1rem;
        padding-bottom: 2rem;
        background-color: $white;
        box-shadow: 0 2rem 2rem rgba($dark, 0.2);
        position: absolute;
        right: 0;
        left: 0;
        top: 4.25rem;
    }
    @include media-breakpoint-up(lg) {
        justify-content: space-between;
    }
    .nav-item {
        margin-left: 1rem;
        margin-right: 1rem;
    }
    .nav-link {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}

.icons-nav {
    display: flex;
    flex-direction: row;
    list-style: none;
    position: absolute;
    right: 2rem;
    top: 1.75rem;
    .nav-item {
        margin-left: 1rem;
    }
    @include media-breakpoint-down(sm) {
        right: 1.25rem;
        top: 1.25rem;
    }
}

.profile-image {
    width: inherit;
    border-radius: 50%;
    display: inline-block;
    width: 40%;
}

.profile-image-2 {
    width: 100%;
    border-radius: 50%;
    display: inline-block;
}

.navbar-toggler {
    // position: fixed;
    // top: 1.25rem;
}

.container-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.user-header {
    padding-bottom: 1.2em;
    border-bottom: 1px solid lightgrey;
}

// TABS
.nav-pills {
    .nav-item {
        text-align: center;
        .nav-link {
            border-radius: 0;
            color: $dark;
            background-color: $white;
            border-right: 0.25rem solid transparent;
            border-bottom: none;
            &.active,
            &:focus {
                color: $primary !important;
                border-right: 0.25rem solid $primary !important;
                border-bottom: none;
                background-color: rgba(196,157,104,0.2);
                fill: $primary !important;
                stroke: $primary !important;
            }
            &:hover {
                color: $dark;
                border-bottom: none;
            }
        }
    }
}

.badge-timer {
    top: 2.125rem !important;
    left: 50%;
    color: $dark;
}
.navbar-icons-right {
    font-weight: 300;
    font-size: 0.65em;
}
.button-logout {
    margin-right: 5rem;
    color: #C49D68;
}
