// FILTER OFFCANVAS

.filter-item{
  margin-bottom: 1.5rem;

  .blocked-order-info-container {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
.filter-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  padding: 1rem;
  background-color: $white;
  box-shadow: 0 0 1rem rgba($dark,.2);

  .remove-filters{
    background-color: transparent;
    text-decoration: none;
    margin-right: 1.5rem;
  }
}

.filter-tag-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
  .filter-tag {
    background-color: $light;
    color: gray;
    border-radius: 100px;
    padding: 0.25rem 0.75rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: -moz-max-content;
    width: max-content;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    font-size: .875rem;

    svg {
      height: 1rem;
      width: 1rem;
      margin-left: 1rem;
    }
  }
}
