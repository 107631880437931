// Swiper Slider
.swiper-container-hero {
	width: 100vw;
	height: 100vh;
	min-height: 46rem;
	position: absolute !important;
	top:0;
	left: 0;
	z-index: -1;
	margin: 0;
		&:after {
		  content: "";
		  display: block;
		  position: absolute;
		  top: 0;
		  left: 0;
		  height: 100%;
		  width: 100%;
		  z-index: 1;
		  background-color: rgba($dark,.6);
		}
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
	display: flex;
    z-index: 1;
    transition-property: transform;
    box-sizing: content-box;
}

.swiper-slide {
	text-align: center;
	font-size: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0;
	position: relative;
	flex-shrink: 0;
    width: 100%;
    height: 100%;

	img, video {
		width: 100%;
		height: 100%;
		-o-object-fit: cover;
		object-fit: cover;
	}
}

.swiper-pagination {
		position: relative !important;
		margin-top: 1rem;
	}
	.swiper-pagination-bullet-active {
		background-color: $primary;
	}
