// BUTTONS
.btn {
	border-radius: 0;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: .02rem;
	border: .0836rem;
	padding: .5rem 1.8rem;
	transition: .15s;
	color: $white;
	min-width: 3.344rem;
	width: auto;
	background-color: $primary;
	cursor: pointer;
	&:hover, &:active , &:focus {
		background-color: darken($primary,8%);
		color: $white;
	}
}

.btn-mini {
	transform: scale(.85);
}

.btn-secondary {
	background-color: $secondary;
}
.btn-primary-outline {
	border: .0836rem solid rgba($primary,1);
	background-color: rgba($white,0);
	color: $primary;
}

.btn-link , a:not([href]):not([tabindex]){
	cursor: pointer;
	color: $primary;
	background-color: transparent;
	&:hover {
		color: darken($primary, 5%);
		background-color: transparent;
	}
}

.btn-text {
	cursor: pointer;
	color: $primary;
	background-color: transparent;
	display: flex;
	justify-content: space-around;
	&:hover {
		color: $dark;
		background-color: transparent;
	}
	span{
		color: $dark;
	}
}

.btn-sso {
	cursor: pointer;
	border-radius: .625rem;
	text-align: center;
	box-shadow: 0 0 1.25rem rgba($black,.12);
	display: grid;
	grid-template-columns: 1.5rem auto;
	align-items: center;
	padding-left: 1rem;
	text-transform: none;
	width: 100%;
	max-width: 18rem;
	.btn-label {
		margin-right: 1rem;
		img {
			height: 1.5rem;
			width: 1.5rem;
		}
	}
}

.btn-apple {
	background-color: rgba(0,0,0,.92) !important;
	&:hover, &:active, &:focus {
		color: $white;
	}
}
.btn-fb {
	background-color: #166fe5 !important;
	&:hover, &:active, &:focus {
		color: $white;
	}
}
.btn-google {
	background-color: $white !important;
	color: $black;
	&:hover, &:active, &:focus {
		color: $black;
	}
}

.btn-logout {
	position:absolute;
	width: auto;
	right: 1rem;
	top: 1rem;
	background-color: rgba($primary,.1);
	box-shadow: none;
	padding: .5rem 1.5rem .5rem 1rem;
	@include media-breakpoint-down(sm) {
		padding: 1rem;
	}

	.btn-logout-icon {
		img {
			opacity: .7;
		}
	}

	.btn-logout-text {
		margin-top: .25rem;
		text-transform:none;
		font-weight: 700;
		color: rgba($primary,.7);

		@include media-breakpoint-down(sm) {
			display: none;
		}
	}

	&:hover, &:active, &:focus {
		.btn-logout-icon {
			img {
				opacity: 1;
			}
		}
		.btn-logout-text {
			color: rgba($primary,1);
		}
	}

}

.btn-pdf {
	width: 100%;
	max-width: none;
	background-color: rgba($success,0);
	box-shadow: none;
	padding: .5rem 1.5rem;

	.btn-pdf-text {
		text-transform:none;
		font-weight: 700;
		color: rgba($success,.7);
	}

}

.download-app {
	margin-top: 1rem;
	img {
		height: 2rem;
	}
}



a {
	-webkit-transition: all .3s ease;
	color: $primary;
	transition: all .3s ease;
	font-weight: 500;
	text-decoration: underline;
	&:hover {
		color: darken($primary, 5%);
	}

	a:hover,
	a:focus,
	a:visited {
		outline: none !important;
		text-decoration: none !important
	}

	input {
		outline: none !important;
	}

	button {
		outline: none !important;
	}
}


.anchor-div{
	cursor: pointer;
}

a, .anchor-div {
	-webkit-transition: all .3s ease;
	color: $primary;
	transition: all .3s ease;
	font-weight: 500;
	text-decoration: underline;
	&:hover {
		color: darken($primary, 5%);
	}
}

.dropup > .dropdown-toggle, .show > .btn-success.dropdown-toggle {
    color: $primary;
}
