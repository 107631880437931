// FORMS

form {
	width: 100%;
	.form-group {
		margin-bottom: 2rem;
	}
	label {
		// font-size: .75rem;
		margin-bottom: 0;
		color: $black;
		font-weight: 500;
	}
	.form-control {
		border-radius:0;
		border: none;
		border-bottom: .125rem solid $black;
		height: 2.25rem;
		padding-left: 0;
		background-color: rgba($white,0);
		&:focus, &:active {
			border:0;
			box-shadow: none;
			border-bottom: .135rem solid $black;
			background-color: rgba($white,0);
		}
	}

	.form-control-admin {
		border-radius:0;
		border: none;
		height: 2.25rem;
		padding-left: 0;
		background-color: #F0F0F0;
		width: 100%;
		&:focus, &:active {
			border:none;
			box-shadow: none;
			background-color: rgba($medium,0);
		}
	}

	.form-control-admin::placeholder {
		padding: 1em;
		color: gray;
	}

	.input-required {
		color: $danger;
	}
}
input::-moz-placeholder {
	color: $medium !important;
}
input:-ms-input-placeholder {
	color: $medium !important;
}
input::placeholder {
	color: $medium !important;
}

.form-check-input:checked {
	background-color: $primary;
	border-color: $primary;
}

.form-check {
	padding-left: inherit;
	margin-left: 1rem;
}

.check-message {
	background-color: rgba($primary,.035);
	font-size: .875rem;
	border-radius: .875rem;
	padding: .75rem .5rem .5rem .5rem;
	margin-left: .75rem;
	margin-top: .5rem;
}

.form-check-input:focus {
	border-color: lighten($primary,10%);
	outline: 0;
	box-shadow: 0 0 0 .25rem rgba($primary,.25);
}

.form-row-import {
	background-color: #ECECEC;
	border-radius: 1rem;
	padding: 1rem 1rem 0 1rem;
	max-width: 30rem;
	margin-left: auto;
	margin-right: auto;
	.form-group {
		margin-bottom: 1rem;
	}
	.form-control {
		font-size: 2rem;
		padding-left: 2rem;
		color: rgba($dark,.7);
	}
}

.form-floating {
	.form-control {
		padding: 1rem .75rem 1rem .1rem;
	}
	label {
		padding-left: 0;
	}
}
