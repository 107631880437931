.banner {
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items:flex-start;
	padding: 1.5rem;
	max-width: 70rem;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
	@include media-breakpoint-down(lg) {
		align-items: center;
	}
	.home-title {
		text-transform: uppercase;
		color: rgba($white,.9);
		font-size: 4rem;
		line-height: 1.1;
		font-weight: 100;
		padding-bottom: 2rem;
		padding-top: 3rem;
		@include media-breakpoint-down(lg) {
			font-size: 3rem;
			// text-align: center;
			padding-top: 3rem;
		}
	}

}

.wow-features {
	display: grid;
	grid-gap: 1rem;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: center;
	// background-color: rgba($dark,.5);
	// margin-top: 1rem;
	margin-bottom: 2rem;
	padding: 1rem;
	.feature-item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		text-align: center;
		// margin-bottom: 2rem;
		.feature-img {
			// border-radius: 100px;
			// background-color: $secondary;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: .5rem;
			// margin-right: 1rem;
			margin-top: .25rem;
			// margin-bottom: .25rem;
			img {
				height: 3rem;
				width: 3rem;
				opacity: .8;
			}
		}
		.feature-info {
			width: 100%;
		}
		.feature-title {
			color: $white;
			font-weight: 500;
			background-color: $secondary;
			text-transform: uppercase;
			// width: auto;
			display: inline;
			padding: 0 .5rem;
		}
		.feature-description {
			color: $light;
			font-size: .875rem;
		}
		@include media-breakpoint-down(lg) {
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			text-align: center;
		}
	}

	@include media-breakpoint-down(lg) {
		grid-template-columns: 1fr;
	}
}

.product-detail-section {
	padding-top: 1rem;
	padding-bottom: 3rem;
}
.wow-section {
	min-height: 65vh;
	padding: 4rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.page-title {
	color: $dark;
	font-family: 'DM Sans', sans-serif;
	font-size: 3.5rem;
	font-weight: 900;
	word-wrap: break-word;
	line-height: 1.2;
	@include media-breakpoint-down(lg) {
		font-size: 2.5rem;
	}
}
.section-title {
	color: $dark;
	font-family: 'DM Sans', sans-serif;
	font-size: 2rem;
	font-weight: 900;
	word-wrap: break-word;
	text-align: left;
	// @include media-breakpoint-up(lg) {
	// 	margin-bottom: 2.75rem;
	// }
	// @include media-breakpoint-down(lg) {
	// 	margin-bottom: 1.5rem;
	// }
}
.product-subtitle {
	color: $medium;
	// font-family: 'NewYork', serif;
	font-family: 'DM Sans', sans-serif;
	font-size: .7rem;
	text-transform: uppercase;
	font-weight: 100;
	margin-bottom: 1rem;
}

// GRID VIEW
.section-grid {
	display: grid;
	grid-gap: 1.5rem;
	grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
	// grid-auto-rows: auto-fill;
	grid-auto-flow: dense;
	list-style: none;
	margin-bottom: 3rem;
	.grid-item {
		background-color: $white;
		height: 100%;
		cursor: pointer;
		width: 100%;
		box-shadow: 0 0 1rem rgba($dark,.05);
		transition-duration: .3s;
		&:hover {
			box-shadow: 0 1rem 1rem rgba($dark,.15);
			transform: translateY(-.25rem);
		}
		.product-image {
			height: 15rem;
			width: 100%;
			// -o-object-fit: cover;
			// object-fit: cover;
			// display: flex;
			// align-items: flex-start;
			// justify-content: left;
			// flex-direction: column;

			img {
				width: 100%;
				height: 100%;
				-o-object-fit: cover;
				object-fit: cover;
			}
			.product-stock {
				display: inline-block;
				position: relative;
				bottom: .75rem;
				font-size: .75rem;
				letter-spacing: .03rem;
				text-transform: uppercase;
				font-weight: 500;
				padding: .25rem .5rem .25rem 1.5rem;
				color: $white;
				background-color: $medium;
				border-radius: 50px;
			}
			.in-stock {
				background-color: $secondary;
			}
			.no-stock {
				background-color: $tertiary;
			}
		}

		.product-item-details {
			padding: 1.25rem 1.5rem;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			.product-detail-image {
				background-color: rgba($white,1);
				box-shadow: 0 0 .5rem rgba($dark,.05);
				padding: .25rem;
				height: 7rem;
				width: 7rem;
				margin-top: -6rem;
				margin-left: auto;
				img {
					height: 100%;
					width: 100%;
					-o-object-fit: contain;
					object-fit: contain;
				}
			}
			.product-title {
				font-size: 1.5rem;
				font-weight: 600;
				color: $dark;

				.product-title-measure{
					color: $secondary;
				}
			}
			.product-description, .product-size, .product-weight {
				font-size: .875rem;
				font-weight: normal;
				color: $medium;
				margin-bottom: .5rem;
			}
			.product-size {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				line-height: 1;
			}
			.product-weight {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				line-height: 1;
			}
			.product-item-footer {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				align-items: flex-end;
			}

			.icon-mini {
				height: 1rem;
				width: 1rem;
				margin-right: .5rem;
			}
		}
	}
}

.detail-price {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-end;
	height: 100%;
	width: 100%;
	@include media-breakpoint-down(lg) {
		// flex-direction: column;
		justify-content: space-between;
		// align-items: center;
	}
}
.product-price {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items:baseline;
	flex-wrap: wrap;
	color: $secondary;
	.currency {
		font-size: 1.125rem;
		font-weight: 600;
		margin-right: .25rem;
	}
	.import {
		font-size: 1.5rem;
		font-weight: 600;
	}
	.units-type {
		width: 100%;
		font-size: .75rem;
		color: $medium;
		margin-top: -.25rem;
	}
}
// LIST VIEW
.cart-container {
	min-height: 100vh;
	padding-bottom: 6rem;
	background-color: $white;
}

.section-list {
	display: grid;
	// grid-gap: 1.5rem;
	grid-template-columns: 1fr;
	grid-auto-flow: dense;
	list-style: none;
	margin-bottom: 3rem;
	padding: 0;
	background-color: $white;

	.list-item {
		display: grid;
		grid-template-columns: auto 1fr;
		// background-color: $light;
		// grid-template-columns: repeat(auto-fill, minmax(6fr, 1fr));
		grid-gap: 1rem;
		padding: 1rem .5rem;
		border-bottom: 1px solid $light;
		transition: .2s;
		align-items: center;
		cursor: pointer;
		&:hover {
			background-color: rgba($light,.15);
			// box-shadow: 0 0 1rem rgba($dark,.15);
		}
		@include media-breakpoint-down(lg) {
			// display: block;
			grid-template-columns: repeat(auto-fill, minmax(60vw, 1fr));
		}

		.product-info {
			display: flex;
			flex-direction: row;
			justify-content: flex-start;
			align-items: flex-start;
		}

		.product-image {
			height: 4rem;
			width: 4rem;
			background-color: $white;
			img {
				width: 100%;
				height: 100%;
				-o-object-fit: contain;
				object-fit: contain;
			}
		}
		.product-item-details {
			margin-left: 1rem;
			width: 100%;
			padding-right: 1rem;
			.product-title {
				display: inline;
				font-size: 1.125rem;
				font-weight: 500;
				color: $dark;
				margin-top: .5rem;
				padding-right: .5rem;

				.product-title-measure{
					color: $secondary;
				}
			}
			.product-stock {
				display: inline-block;
				margin-left: auto;
			}
			.product-description{
				font-size: .875rem;
				font-weight: normal;
				color: $medium;
				padding-right: 1rem;
				// margin-bottom: .5rem;
			}
			.product-size, .product-weight {
				line-height: 1;
				font-size: .75rem;
				padding: .2rem;
				background-color: $light;
				.icon-mini {
					height: .75rem;
					width: .75rem;
					margin-right: .5rem;
				}
			}
			.product-size {
				display: inline-block;
				margin-right: .5rem;

			}
			.product-weight {
				display: inline-block;
			}
		}
		.product-actions {
			display: flex;
			flex-direction: row;
			align-items:center;
			flex-wrap: wrap;
			justify-content: end;

			.total-price{
				margin-right: 2rem;
			}

			.actions{
				display: flex;
				gap: 1rem;
				padding: .25rem 0;

				form{
					width: auto;
				}
			}
			.product-price {
				display: flex;
				flex-direction: row;
				justify-content: flex-end;
				align-items:baseline;
				flex-wrap: wrap;
				color: $secondary;
				padding-right: 1.125rem;
				.currency {
					font-size: .825rem;
					font-weight: 600;
					margin-right: .25rem;
				}
				.import {
					font-size: 1rem;
					font-weight: 600;

				}
				.units-type {
					width: 100%;
					font-size: .75rem;
					color: $medium;
					margin-top: -.25rem;
					text-align: right;
				}
			}

		}
		.product-discount-cart{
			font-size: 0.625rem;
			letter-spacing: 0.03rem;
			font-weight: 500;
			padding: 0.25rem 0.5rem 0.25rem 0.5rem;
			color: $white;
			background-color: $secondary;
			border-radius: 50px;
			display: flex;
			white-space: nowrap;
		}
	}
}

.number-input {
	display: flex;
	width: auto;
	justify-content: space-between;
	gap: 8px;
	input.quantity{
		text-align: left;
		padding: .25rem;
		max-width: 5rem;
		width: 5rem;
	}
	a {
		padding: .25rem;
		img {
			height: 1.5rem;
			width: 1.5rem;
		}
	}
	.number-input-container {
		margin: auto;
		display: flex;
	}

	input[type="number"].quantity::-webkit-outer-spin-button,
	input[type="number"].quantity::-webkit-inner-spin-button {
		-webkit-appearance: none;
		background: #FFF url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAJCAYAAADgkQYQAAAAKUlEQVQYlWNgwAT/sYhhKPiPT+F/LJgEsHv37v+EMGkmkuImoh2NoQAANlcun/q4OoYAAAAASUVORK5CYII=) no-repeat center center;
		width: 1em;
		border-left: 1px solid #BBB;
		opacity: .5; /* shows Spin Buttons per default (Chrome >= 39) */
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
	}
	input[type="number"].quantity::-webkit-inner-spin-button:hover,
	input[type="number"].quantity::-webkit-inner-spin-button:active{
		box-shadow: 0 0 2px #0CF;
		opacity: .8;
	}

	/* Override browser form filling */
	input:-webkit-autofill {
		background: black;
		color: red;
	}

}


.number-input .increment, .number-input .decrement, .delete-icon, .check-icon, .edit_icon {
	margin: .5rem;
	cursor: pointer;
}

// DISPLAY ONLY INTO SWIPER
.swiper-card-products {
	display:block;
}




// GRID collections
.grid-collections {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	// grid-template-rows: 20rem;
	grid-gap: 1rem;
	grid-auto-flow: dense;
	height: 100%;
	width: 100%;
	margin-bottom: 3rem;
	@include media-breakpoint-down(lg) {
		display: block;
	}
	.collection-item {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		min-height: 15rem;
		height: 100%;
		width: 100%;
		padding: 1.5rem;
		background-image: linear-gradient(rgba($dark,.6),rgba($dark,.6)), url('../../assets/img/bg-03.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		cursor: pointer;
		&:nth-child(1){
			grid-column: span 4;
			grid-row: span 2;
		}
		&:nth-child(2),&:nth-child(3){
			grid-column: span 2;
		}
		&:nth-child(4),&:nth-child(5){
			grid-column: span 3;
		}
		&:hover {
			transition-duration: 1s;
			background-image: linear-gradient(rgba($dark,.4),rgba($dark,.4)), url('../../assets/img/bg-03.jpg');
			// .collection-name,.collection-size {
			// 	opacity: .5;
			// }
		}
		.collection-name {
			font-size: 1.75rem;
			font-weight: 500;
			text-transform: uppercase;
			color: $white;
			word-wrap: break-word;
			// &:hover {
			// 	opacity: 1;
			// }
		}
		.collection-size {
			font-size: 1rem;
			font-weight: 100;
			color: $primary;
			// &:hover {
			// 	opacity: 1;
			// }
		}
		@include media-breakpoint-down(lg) {
			margin-bottom: 1rem;
		}
	}
}





// CUSTOM MADE SECTION
.custom-made-container {
	margin-bottom: 3rem;
	.slogan {
		font-size: .875rem;
		font-weight: 100;
		text-transform: uppercase;
		color: $secondary;
		margin-bottom: .75rem;
	}
	.wow-title {
		font-size: 1.5rem;
		text-transform: uppercase;
		margin-bottom: .25rem;
	}
	.wow-subtitle {
		font-size: 1.25rem;
		text-transform: uppercase;
		margin-bottom: 1.125rem;
	}
	.wow-description {
		font-size: 1rem;
		color: $medium;
		margin-bottom: 1.5rem;
	}

}
.img-h-100 {
	height: 100%;
	min-height: 12rem;
	@include media-breakpoint-down(md) {
		margin-bottom: 1rem;
	}

	img {
		height: 100%;
		width: 100%;
		min-height: 12rem;
		-o-object-fit: cover;
		object-fit: cover;
	}
}


.page-content {
	margin: 7.625rem 0 3rem 0;
}


// CATALOG
.header-section {
	min-height: 65vh;
	padding: 7.625rem 0 3rem 0;
	display: flex;
	flex-direction: column;
}
.header-section-detail {
	min-height: 100vh;
	padding: 7.625rem 0 3rem 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
ul#pills-tab {
	justify-content: center;
}
.section-header {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-start;
	.switch-view-container {
		.nav {
			min-width: 8rem;
			justify-content: flex-end;
			border: 0;
		}
		.nav-tabs .nav-link {
			margin-bottom: 0;
			border: 0;
			padding: 0.5rem 1rem;
			border-radius: 0;
			background-color: rgba($white,1);
			opacity: .5;
			&:hover,&.active,&:focus {
				background-color: rgba($light,.7);
				opacity: 1;
			}
		}
	}
}

// PRODUCT DETAIL
.product-stock {
	font-size: .625rem;
	display: inline-block;
	letter-spacing: .03rem;
	font-weight: 500;
	color: $white;
	background-color: $medium;
	padding: 0.3rem 0.1rem;
    color: #FFFFFF;
    border-radius: 5px;
}
.in-stock {
	background-color: $secondary;
	font-size: 12px;
}
.no-stock {
	background-color: $tertiary;
}

.product-gallery {
	height: 70vh;
	@include media-breakpoint-down(lg) {
		margin-bottom: 3rem;
	}
}

.product-type-grid {
	// display: grid;
	// grid-gap: 1rem;
	// grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr));
	// grid-auto-rows: auto-fill;
	// grid-auto-flow: dense;
	list-style: none;
	margin-bottom: 3rem;

	.product-grid-item {
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: center;
		// background-color: $light;
		height: 100%;
		min-height: 23rem;
		min-width: 15rem;
		max-width: 22rem;
		cursor: pointer;
		width: 100%;
		padding: 1.25rem;
		box-shadow: 0 0 1rem rgba($dark,.05);
		transition-duration: .3s;
		&:hover {
			box-shadow: 0 1rem 1rem rgba($dark,.15);
			transform: translateY(-.25rem);
		}
		.product-grid-image {
			padding: 0;
			height: 10rem;
			width: 10rem;
			img {
				height: 100%;
				width: 100%;
				-o-object-fit: contain;
				object-fit: contain;
				margin-left: auto;
				margin-right: auto;
			}
		}

		.product-grid-item-details {
			padding: .5rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: center;
			text-align: center;
			height: 100%;
			.product-stock {
				position: absolute;
				// top: 10rem;
				// left: 0;
				top: 10rem;
				// margin-top: -2rem;
				// margin-bottom: 1rem;
				// display: none;
			}
			.product-grid-title {
				font-size: .875rem;
				font-weight: 600;
				text-transform: uppercase;
				color: $dark;
				// margin-top: -1rem;
			}
			.product-grid-color {
				font-size: .75rem;
				font-weight: 600;
				// text-transform: uppercase;
				color: $dark;
			}
			.product-grid-finish {
				font-size: .75rem;
				font-weight: 600;
				// text-transform: uppercase;
				color: $medium;
			}
			.product-grid-ref {
				font-size: .75rem;
				font-weight: normal;
				color: $medium;
			}
			.product-grid-size, .product-grid-weight {
				font-size: .75rem;
				font-weight: normal;
				color: $medium;
				margin-bottom: .5rem;
			}
			.product-grid-size {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				line-height: 1;
			}
			.product-grid-weight {
				display: flex;
				justify-content: flex-start;
				align-items: center;
				line-height: 1;
			}


		}

		.product-grid-item-buy {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-top: auto;

			.product-grid-item-footer {
				display: flex;
				flex-direction: row;
				justify-content: center;
				margin-top: -1rem;


			}
			/*.product-grid-item-quantity {
				// display: flex;
				// flex-direction: row;
				// justify-content: space-between;
				// align-items: center;
			}*/
		}
	}
}

.product-grid-price {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items:baseline;
	flex-wrap: wrap;
	color: $secondary;
	margin-top: 1rem;
	.product-grid-currency {
		font-size: 1rem;
		font-weight: 600;
		margin-right: .25rem;
	}
	.product-grid-import {
		font-size: 1.125rem;
		font-weight: 600;
	}
	.product-grid-units-type {
		width: 100%;
		font-size: .625rem;
		color: $medium;
		margin-top: -.25rem;
		text-align: left;
	}
}

.tag-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	.tag {
		background-color: $light;
		color: $dark;
		padding: .25em .75em;
		border-radius: 10rem;
		margin-right: .5rem;
		margin-bottom: .5rem;
		font-size: .75rem;
		// font-weight: 500;
		transition: .3s;
		&:hover {
			background-color: $dark;
			color: $white;
		}
	}
}

.colors-container {
	display: grid;
	grid-gap: 1.25rem 1rem;
	grid-template-columns: repeat(auto-fill, minmax(2.25rem, 1fr));
	grid-auto-flow: dense;
	list-style: none;

	padding: 0;
	.color-item {
		cursor: pointer;
		text-align: center;
		img {
			height: 2rem;
			width: 2rem;
			-o-object-fit: cover;
			object-fit: cover;
			border-radius: 5rem;
			transition: .1s;
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
		}
		.color-name {
			padding-top: .25rem;
			font-size: .75rem;
			line-height: 1;
			text-align: center;
		}
	}
}


// CART SUMMARY
.card-box {
	background-color: rgba($medium,.05);
	padding: 1rem;
	// margin-bottom: 2rem;
	width: auto;
	ul {
		list-style: none;
		padding: 0;
		color: $dark;
		font-size: 1rem;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-column-gap: .5rem;
		text-align: center;
		margin-bottom: 0;
		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
		}
		li {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: .5rem;
			// background-color: rgba($medium,.1);
			@include media-breakpoint-down(md) {
				flex-direction: row;
				margin-bottom: 1rem;
				text-align: left;
			}
			p {
				margin-bottom: 0;
			}
		}
	}
}

.card-wow {
	padding: 1rem;
	background-color: rgba($medium,.05);
	margin-bottom: 1rem;
}

.card-wow-title {
	// color: $medium;
	// font-size: 1.125rem;
	margin-top: 2rem;
	&::before {
		content: "[";
		color: $primary;
	}
	&::after {
		content: "]";
		color: $primary;
	}
}

// TOTAL PRICE RESUME
.cart-footer {
	position: fixed;
	bottom: 0;
	padding: 1rem;
	background-color: $white;
	width: 100%;
	box-shadow: 0 0 1rem rgba($dark,.2);
	.cart-footer-display {
		display: grid;
		grid-auto-rows: auto;
		// grid-auto-columns: auto;
		align-items: center;
		// justify-content: flex-end;
		grid-row-gap: 1rem;
		padding: 0;
		@include media-breakpoint-up(md) {
			display: flex;
			flex-direction: row;
			justify-content: flex-end;
			// display: inline;
			// display: grid;
			// grid-template-columns: repeat(auto-fit, minmax(12rem, 10rem));
			// grid-template-columns: auto 10rem auto;
			// grid-column-gap: 2rem;
			.btn {
				width: 12rem;
				margin-left: 2rem;
			}
		}
	}
	.total-price-header {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: baseline;
		@include media-breakpoint-up(lg) {
			justify-content: flex-end;
		}
		.total-price-title {
			font-size: 1rem;
			font-weight: normal;
			color: $dark;
			margin-right: 2rem;
			// @include media-breakpoint-up(lg) {
			// }
		}
		.total-price-import {
			font-size: 1.25rem;
			font-weight: 900;
			color: $black;
		}
	}
}

.product-tag{
	display: inline-block;
	background-color: $light;
	padding: .2rem;
	line-height: 1;
	font-size: .75rem;
	color: $dark;
	margin-right: 0.5rem;

	.product-tag-label, .weight-value, .quantity-value {
		font-weight: bold;
		margin-right: 2px;
	}
}
