// BS overrides
$primary:       #C49D68;
$secondary:     #9CA89B;
$tertiary:     #7C4C54;
$success:       #198754;
$info:          #0dcaf0;
$warning:       #ffc107;
$danger:        #dc3545;
$black:			#000000;
$dark:          #262626;
$medium:          #808080;
$light:         #F0F0F0;
$white:			#FFFFFF;

$co-color-white: #FFFFFF;
$co-color-black: #000000;

$co-color-gray01: #282C34;
$co-color-gray02: #4D4D4DB0;
$co-color-gray03: #f0f0f04d;

$co-color-blue01: #61DAFB;

$error-fg: #dc3545;

$backgroundColorSwitcherSliderOn: $primary;
$backgroundColorSwitcherSliderOff: desaturate($primary, 50%);
$backgroundColorCircleSwitcherSliderOn: $primary;
$backgroundColorCircleSwitcherSliderOff: $light;

$backgroundColorFileItem: #c49d6833;
