@import 'src/styles/variables/all';


.order-add-wrapper {
  width: 100%;
  height: 100%;
  padding-bottom: 1rem;
  position: relative;

  .order-add-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    align-items: self-end;

    .order-add-header-actions-container {
      margin-left: 1rem;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 10px;

      >div{
        display: flex;
        align-self: end;
      }
    }
  }

  .order-add-wrapper-spinner-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: $white;
    opacity: .7;
    pointer-events: unset;
    z-index: 1;
    top: 0;
  }

  .add-product-form-card {
    background-color: $co-color-gray03;
    margin: 1rem 0;
    padding: 1.25rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    .add-product-form-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      gap: 15px;
      margin-top: 1rem;

      @include media-breakpoint-down(lg){
        flex-direction: column;
      };

      .product-container {
        width: 40%;

        @include media-breakpoint-down(lg){
          width: 100%;
        };
      }

      .lot-quantity-units-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 60%;
        gap: 15px;

        @include media-breakpoint-down(lg){
          width: 100%;
          flex-direction: column;
        };

        .lot-quantity-units-container-item {
          display: flex;
          flex-direction: column;
          width: 25%;

          .quantity-input {
            text-align: left ;
            padding: 1rem ;
          }

          input {
            text-align: right;
          }

          @include media-breakpoint-down(lg){
            width: 100%;
          };
        }

        .add-product-button-container{
          display: flex;
          align-self: end;
          justify-content: center;
          width: 25%;

          @include media-breakpoint-down(lg){
            width: 100%;
            justify-content: end;
          };
        }
      }
    }
  }

  .no-products-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5rem;
    color:$medium;
    opacity: .6;

    >span {
      margin-top: 1rem;
      width: 20%;
    }

    >img {
      width: 5%;
    }
  }

  .order-add-footer-container {
    width: 100%;
    position: fixed;
    display: flex;
    justify-content: end;
    background-color: $white;
    bottom: 0;
    left: 0;
    box-shadow: 0 0 1rem rgba(38,38,38,.2);
    padding: 1rem;
    z-index: 100;

    button {
      margin-right: 1rem;
    }
  }
}


