.tabs-container{
  justify-content: center;
  margin-bottom: 1rem;
  border: 0;

  > .nav-item{

    > .nav-link{
        padding-right: 4rem;
        padding-left: 4rem;
        border: 0;
        color: $secondary;
        text-transform: uppercase;
    }

    > .active{
      border-bottom: 5px solid $primary;
      color: $primary;
    }
  }
}

.component-banner-item {
  margin-bottom: 1rem;
}


.skeleton-container{

  > span{
    padding: .25rem;
    height: 200px;
    margin-bottom: 1rem;
  }

}