// Responsive typography
html, main {
	font-size: .95rem;
	@include media-breakpoint-up(sm) {
		font-size: .95rem;
	}
	@include media-breakpoint-up(md) {
		font-size: .95rem;
	}
	@include media-breakpoint-up(lg) {
		font-size: 1rem;
	}
	overflow-x: hidden;
	min-height: 100vh;
}
