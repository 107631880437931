// ORDERS
.table-striped>tbody>tr:nth-of-type(odd) {
	background-color: rgba($dark,.0);
}

.overflow-menu {
	padding: 1rem;
	opacity: .2;
	&:hover {
		opacity: 1;
	}
}
