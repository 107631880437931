.custom-type-ahead-basic {
  .rbt-aux {
    .rbt-loader,
    spinner-border,
    spinner-border-sm {
      position: absolute;
      right: 10px;
      bottom: 10px;
    }

    >button {
      background-color: transparent;
      border: none;
      bottom: 5px;
      cursor: pointer;
      position: absolute;
      right: 10px;
    }
  }

  .rbt-menu {
    background-color: rgb(255 255 255);
    border-radius: 8px;
    box-shadow: 0 2px 16px rgb(52 67 74 / 20%);
    opacity: 1;
    transform: none;
    z-index: 10;

    >a{
      &:active{
        background-color: transparent;
      }
      >div{
        width: 100%;
        margin-bottom: 0;
      }
    }
  }

  //.rbt-menu-pagination-option{
  //  color: $primary;
  //}

  .dropdown-menu {
    padding: 0;
  }
}
