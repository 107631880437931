@import "src/styles/variables/all";

.attached-files-list{
  max-height: 15rem;
  overflow: auto;

  .attached-files-list-item-container{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding:0.5rem;
    flex-wrap: wrap;
    word-break: break-word;

    &:hover{
      background-color: $backgroundColorFileItem;
    }
  }

}
