// MODAL
.modal {
	padding-right: 1rem;
	padding-left: 1rem;
}
.modal-content {
	padding: 1rem;
	border-radius: 0rem;
	box-shadow: 0 0 .625rem rgba($dark,.15);
}
.modal-header, .modal-footer {
	border:none;
}

